<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img :src="require('@/assets/images/logos/dente.png')" max-height="60px" max-width="60px" alt="logo"
              contain class="me-3 "></v-img>

            <h2 class="text-2xl font-weight-semibold">
              Ihealth-Plus
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="mb-2 text-center">
            Enviar e-mail com uma nova senha
          </p>
        </v-card-text>

        <v-card-text v-if="mensagemErroTrue">
          <v-alert dense outlined type="error">
            {{ this.mensagemErro }}
          </v-alert>
        </v-card-text>

        <!-- login form -->
        <v-card-text ref="form">
          <v-form>
            <v-text-field v-model="email" outlined label="E-mail" hide-details class="mb-3"
              :rules="[() => !!email || 'Campo obrigatório']" :error-messages="errorMessages" ref="email">
            </v-text-field>

            <v-btn block color="primary" class="mt-6" @click="esqueciSenha()">
              Enviar e-email
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <router-link :to="{ name: 'login' }">
            Voltar
          </router-link>
        </v-card-text>

        <v-snackbar v-model="snackbar" :color="color">
          {{ text }}

          <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
              Fechar
            </v-btn>
          </template>
        </v-snackbar>

      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img class="auth-mask-bg" height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)">

    <!-- tree -->
    <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img>

    <!-- tree  -->
    <v-img class="auth-tree-3" width="377" height="289" src="@/assets/images/misc/tree-3.png"></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline

import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const isPasswordVisible = ref(false)

    return {
      isPasswordVisible,
      email: "",
      senha: "",
      formHasErrors: false,
      errorMessages: '',
      mensagemErroTrue: false,
      mensagemErro: '',
      snackbar: false,
      text: '',
      color: 'success',

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  methods: {
    esqueciSenha: function () {
      this.formHasErrors = false

      Object.keys(this.form).forEach(f => {
        if (!this.form[f]) {
          this.formHasErrors = true
        }

        this.$refs[f].validate(true)
      })

      if (!this.formHasErrors) {
        this.$http.post("esqueciSenha", { email: this.email })
          .then((res) => {
            if (res.data.status != undefined && res.data.status == 200) {
              this.color = "success"
              this.text = res.data.msg
              this.snackbar = true

              setTimeout(() => {
                this.$router.push('login')
              }, 3000)

            } else if (res.data.status != undefined && res.data.status == 500) {
              this.mensagemErroTrue = true
              this.mensagemErro = res.data.msg
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    submit() {

    },
  },

  computed: {
    form() {
      return {
        email: this.email,
      }
    },
  },

  watch: {
    name() {
      this.errorMessages = ''
    },
  },

  beforeMount() {
    var logado = localStorage.getItem("login")
    if (logado != undefined && logado == "true") {
      this.$router.push('dashboard')
    }
  }


}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
